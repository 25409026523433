
// Artifacts
export const faCirclet = {
  prefix: 'faga',
  iconName: "Circlet",
  icon: [
    14, 14,
    [],
    null,
    "m12.793 5.048.269.269L10.5 7.136 7.269 3.364l.809-.809L7 1.206 5.922 2.555l.809.809L3.5 7.136.938 5.317l.269-.269L0 4.171l2.958 7.007L7 12.794l4.042-1.616L14 4.171ZM8.732 9.07l-.892.149a.673.673 0 0 0-.543.5l-.25 1a.048.048 0 0 1-.094 0l-.25-1a.673.673 0 0 0-.543-.5l-.892-.149a.05.05 0 0 1 0-.1l.892-.149a.673.673 0 0 0 .543-.5l.25-1a.048.048 0 0 1 .094 0l.25 1a.673.673 0 0 0 .543.5l.892.149a.05.05 0 0 1 0 .1z"
  ]
} as const

export const faFlower = {
  prefix: 'faga',
  iconName: "Flower",
  icon: [
    14, 14,
    [],
    null,
    "M11.279 5.044a2.554 2.554 0 0 0-1.6.9c1.536-1.751 3.029-5.151.032-5.305-.015-.115-.2-.387-.4-.276A5.942 5.942 0 0 0 7.2 5.08C6.705 2.519 4.078.798 3.016 1.108c-.207.324-.056.607-.261.793-.18.163-.58-.016-.619.249-.324 1.729.945 3.632 2.758 4.532C3.113 6.396.165 6.688 0 8.83c0 .939 1.269 1.377 3.191 1.377a3.541 3.541 0 0 0 3.128-1.468c-2.541 2.727-.544 3.278 2.192 1.78 1.073-.621.8-1.4.027-2.295 1.395.853 3.755-.174 3.877-1.72-.001-.632-.273-1.447-1.136-1.46zM7.521 6.552a.5.5 0 0 1-.441.5l-.1.637-.08-.656a.5.5 0 0 1-.362-.594l-.467-.567.557.377a.5.5 0 0 1 .706-.086l.459-.381-.356.492a.5.5 0 0 1 .084.278zM14 8.428c-.519.249-.77 1.7-1.586 2.337a3.966 3.966 0 0 1-2.678.5 3.463 3.463 0 0 1-1.881 2.393.041.041 0 0 1-.05-.064 4.294 4.294 0 0 0 1-3.174 1.125 1.125 0 0 0 .151-1.781c.344.065.828 1.348.876 2.125 0 0 1.857-1.178 2.355-1.37a6.666 6.666 0 0 0-2.31 1.245 5.933 5.933 0 0 1 1.269-1.5c.611-.53 2.402-.548 2.854-.711z"
  ]
} as const

export const faGoblet = {
  prefix: 'faga',
  iconName: "Goblet",
  icon: [
    14, 14,
    [],
    null,
    "M9.546 8.705c4.121-2.3 4.121-3.555 4.12-6.894H.428c0 3.339 0 4.59 4.12 6.894 0 1.75 1.739 1.28 1.739 2.187.076.765-.192 1.207-.567 1.275-1.21.167-3.368.939-3.383 1.765h9.42c-.015-.826-2.173-1.6-3.383-1.765-.374-.068-.644-.509-.567-1.275 0-.907 1.739-.437 1.739-2.187zM7.461 5.791l-.348 1.393a.066.066 0 0 1-.066.052.066.066 0 0 1-.066-.052l-.348-1.393a.941.941 0 0 0-.757-.7l-1.245-.206a.068.068 0 0 1 0-.135l1.245-.207a.939.939 0 0 0 .757-.7l.348-1.393a.066.066 0 0 1 .066-.051.067.067 0 0 1 .066.051l.348 1.393a.939.939 0 0 0 .757.7l1.245.207a.068.068 0 0 1 0 .135l-1.245.207a.941.941 0 0 0-.757.699zm5.881-4.408H.658A.657.657 0 0 1 0 .726.657.657 0 0 1 .658.068h12.684A.657.657 0 0 1 14 .726a.657.657 0 0 1-.658.657Z"
  ]
} as const

export const faPlume = {
  prefix: 'faga',
  iconName: "Plume",
  icon: [
    14, 14,
    [],
    null,
    "M12.344 0a4.52 4.52 0 0 0-1 .186 4.469 4.469 0 0 0-1.48.6c-.235.14-.24.324-.311.274a.717.717 0 0 0-.467-.033C5.652 3.737 5.575 3.893 3.82 6.494c-.114.046-.285-.11-.285-.11-.06.546-.215.546-.526 1.168a2.75 2.75 0 0 0-.374 2.036 2.49 2.49 0 0 1-.723-.245l.467.39a2.681 2.681 0 0 1-.934.078l.623.467s-.39.389-.546.389l.519.074a18.034 18.034 0 0 0-1.506 3.207A.04.04 0 0 0 .58 14l.593-.3a.038.038 0 0 0 .02-.025 5.139 5.139 0 0 1 1.722-2.7l.479.549a1.881 1.881 0 0 1-.155-.779l.856.156c-.39-.39-.35-.56-.35-.56a4.067 4.067 0 0 0 1.6-.034 8.175 8.175 0 0 0 1.705-.865c.084-.04-.146-.082-.066-.124.08-.042.329-.095.413-.145A20.31 20.31 0 0 0 9.95 7.147a11.77 11.77 0 0 0 2.367-2.7c.043-.074-.36-.2-.526-.231.177.021.616.121.654.048a6.256 6.256 0 0 0 .86-2.34c.056-.679.679-2.003-.961-1.924zM3.083 9.577c1.952-4.205 7.552-7.631 7.552-7.631 1.77-1.385-3.96 2.438-7.552 7.709z"
  ]
} as const

export const faSands = {
  prefix: 'faga',
  iconName: "Sands",
  icon: [
    14, 14,
    [],
    null,
    "M11.695 12.708h-.818v-.944c0-3.536-3.274-3.709-3.274-4.915v-.071a.855.855 0 0 1 .392-.72c.868-.56 2.773-1.311 2.877-3.895a.216.216 0 0 0-.215-.225H3.343a.217.217 0 0 0-.216.225c.1 2.584 2.009 3.335 2.877 3.9a.856.856 0 0 1 .393.72v.071c0 1.206-3.274 1.379-3.274 4.915v.944h-.819a.215.215 0 0 0-.215.21v.862a.215.215 0 0 0 .215.215h9.391a.216.216 0 0 0 .216-.215v-.862a.216.216 0 0 0-.216-.215Zm-4.695 0H3.9c0-2.757 2.757-2.154 2.757-3.274V5.988S5.018 5.04 5.018 4.351h3.963c0 .689-1.637 1.637-1.637 1.637v3.446c0 1.12 2.757.517 2.757 3.274zM11.911.215v.862a.216.216 0 0 1-.216.215H2.304a.215.215 0 0 1-.215-.215V.215A.215.215 0 0 1 2.304 0h9.391a.216.216 0 0 1 .216.215z"
  ]
} as const
// Artifacts End

